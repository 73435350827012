import './config'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'

// normalize and basic styles for application
import 'normalize.css'
import 'antd/dist/antd.css'
import 'animate.css/animate.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-circular-progressbar/dist/styles.css'
import 'sources/styles/styles.scss'

ReactDOM.render(<App />, document.getElementById('root'))

let lockClickEvent = false
document.getElementById('root').addEventListener('click', e => {
  console.log('click ...')
  if (lockClickEvent) {
    e.stopPropagation()
    e.preventDefault()
    return
  }

  lockClickEvent = true

  setTimeout(() => {
    lockClickEvent = false
  }, 500)
})
