import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
// import powerOfBrands from 'sources/images/power-of-brands.jpg'
import menuImage from 'sources/images/menuTop.jpg'

export default function Menu(props) {
  useEffect(() => {
    idleActions.set(40000)
  }, [])

  return (
    <div className={styles.container}>
      <img src={menuImage} alt="Power of brands" />

      {/* <h2 className={styles.title}>
        The power of our
        <br />
        brands
      </h2> */}
      <Header
        title="The power of our brands"
        path={[{ title: 'Sustainability', to: '/power-of-brands/menu' }]}
        // backTo="/power-of-brands"
        hideHome
        offsetTop={550}
      />

      <p>
        The role and power of brands is increasingly important. With less time and more choices, consumers tend to
        connect only with brands that are relevant to their values and offer what they need.
        <br />
        <br />
        To drive profitable growth, we focus on brand desirability.
      </p>

      <Link to="/power-of-brands/menu/second-menu" className={styles.more}>
        Explore more <span className="fa">&#xf054;</span>
      </Link>
    </div>
  )
}
