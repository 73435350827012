import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
// import { message } from 'antd'

import styles from './styles.module.scss'
import powerOfBrands from 'sources/videos/Power-of-brands.mp4'

const toUpload = {
  powerOfBrands,
}

class Videos {
  constructor() {
    this.init()
  }

  _video = {}

  async init() {
    // let msg = message.loading('Downloading ...', 0)

    try {
      for (const videoName of Object.keys(toUpload)) {
        console.info('Downloading', videoName, toUpload[videoName])
        const video = await fetch(toUpload[videoName])
        const file = await video.blob()
        console.info('Done')

        this._video[videoName] = URL.createObjectURL(file)
      }

      console.log('Ready')
      // message.success('All videos are downloaded', 10)
    } catch (e) {
      console.error(e)
      // message.error(e.message, 10)
    } finally {
      // msg()
    }
  }

  get video() {
    return this._video
  }
}

export const VIDEOS = new Videos()

export default function Home(props) {
  let timeout

  const iniTimeout = () => {
    timeout = setTimeout(() => {
      props.history.push('/power-of-brands')
    }, 30000)
  }

  iniTimeout()

  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  })

  return (
    <Link className={styles.container} to="/power-of-brands/menu">
      <video style={{ display: 'none' }} autoPlay muted>
        <source src={powerOfBrands} type="video/mp4" />
      </video>
      <h1 className={styles.title}>
        The power of
        <br />
        our brands
      </h1>

      <div className={styles.trigger}>
        <div className={styles.hand} />
      </div>
    </Link>
  )
}
