import React from 'react'
import { Link } from 'react-router-dom'

import styles from './styles.module.scss'
import powerOfBrands from 'sources/videos/Power-of-brands.mp4'

import { VIDEOS } from 'containers/Home'

export default function PowerOfBrands(props) {
  return (
    <Link className={styles.container} to="/power-of-brands/menu">
      <h1 className={styles.title}>The power of our brands</h1>

      <video className={styles.video} autoPlay onEnded={() => props.history.push('/')}>
        <source src={VIDEOS.video.powerOfBrands || powerOfBrands} type="video/mp4" />
      </video>

      <div className={styles.trigger}>
        <div className={styles.hand} />
      </div>
    </Link>
  )
}
