import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'

export default function SecondMenu(props) {
  useEffect(() => {
    idleActions.set()
  }, [])

  return (
    <div className={styles.container}>
      <Header title="" path={[{ title: 'The power of OUR brands', to: '/power-of-brands/menu/second-menu' }]} />

      <div className={styles.blocks}>
        <Animated animationIn="fadeInRight" isVisible animateOnMount={false}>
          <Link to="/power-of-brands/menu/second-menu/why-brands-matter" className={styles.block}>
            <span>
              Why brands
              <br />
              matter?
            </span>
          </Link>
        </Animated>
        <Animated animationIn="fadeInLeft" isVisible animateOnMount={false}>
          <Link to="/power-of-brands/menu/second-menu/our-brands" className={styles.block}>
            <span>Our three main brands</span>
          </Link>
        </Animated>
        <Animated animationIn="fadeInRight" isVisible animateOnMount={false}>
          <Link to="/power-of-brands/menu/second-menu/positioning" className={styles.block}>
            <span>Positioning and target audience</span>
          </Link>
        </Animated>
      </div>
    </div>
  )
}
