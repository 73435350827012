import React, { useEffect } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import ourBrandsLogos from 'sources/images/our-brands-logos.jpg'

export default function MainBrands(props) {
  useEffect(() => {
    idleActions.set(15000, '/power-of-brands/menu/second-menu')
  }, [])

  return (
    <div className={styles.container}>
      <Header
        title="Our three main brands"
        path={[
          { title: 'The power of OUR brands', to: '/power-of-brands/menu/second-menu' },
          { title: 'Our three main brands', to: '/power-of-brands/menu/second-menu/our-brands' },
        ]}
        backTo="/power-of-brands/menu/second-menu"
        dark={true}
        offsetTop={-50}
      />

      <Animated animationIn="fadeInDown" animateOnMount animationInDelay={100}>
        <img src={ourBrandsLogos} alt="logos" className={styles.logos} />
      </Animated>

      <p className={styles.desc}>
        We have about 60 brands in our portfolio and the three biggest and most strategically important are Electrolux,
        AEG and Frigidaire.
        <br />
        <br />
        We are focused on increasing desirability for these three distinct and recognized brands.
      </p>
    </div>
  )
}
