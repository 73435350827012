import React from 'react'
import { Router, Route } from 'react-router'
import { Provider } from 'react-redux'
import history from 'utils/history'
import store from 'store'
import RouteSlider from 'components/RouteSlider'

import Home from '../Home'
import PowerOfBrands from '../PowerOfBrands'
import Menu from '../Menu'
import SecondMenu from '../SecondMenu'
import WhyBrandsMatter from '../WhyBrandsMatter'
import MainBrands from '../MainBrands'
import Positioning from '../Positioning'

import styles from './styles.module.scss'

history.replace('/')

export default function App(props) {
  return (
    <div className={styles.wrapper}>
      <Provider store={store}>
        <Router history={history}>
          <RouteSlider>
            <Route path="/" component={Home} exact />
            <Route path="/power-of-brands" component={PowerOfBrands} exact />
            <Route path="/power-of-brands/menu" component={Menu} exact />
            <Route path="/power-of-brands/menu/second-menu" component={SecondMenu} exact />
            <Route path="/power-of-brands/menu/second-menu/why-brands-matter" component={WhyBrandsMatter} exact />
            <Route path="/power-of-brands/menu/second-menu/our-brands" component={MainBrands} exact />
            <Route path="/power-of-brands/menu/second-menu/positioning" component={Positioning} exact />
          </RouteSlider>
        </Router>
      </Provider>
    </div>
  )
}
