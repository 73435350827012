import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import Slider from 'react-slick'
import cn from 'classnames'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'
import brandsGraph from 'sources/images/new-brands-graph.png'

export default class Positioning extends Component {
  state = {
    index: 0,
  }

  componentDidMount = () => {
    idleActions.set()
    this.setInterval()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 30000)
  }

  onChangeIndex = index => {
    this.setState({ index }, () => {
      this.slider.slickGoTo(this.state.index)
      this.setInterval()
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/power-of-brands/menu/second-menu')
    }
  }

  render = () => {
    const { index } = this.state

    return (
      <div className={styles.container} onClick={this.onNextSlide}>
        <Header
          title="Positioning and target audience"
          path={[
            { title: 'The power of OUR brands', to: '/power-of-brands/menu/second-menu' },
            { title: 'Positioning', to: '/power-of-brands/menu/second-menu/positioning' },
          ]}
          backTo="/power-of-brands/menu/second-menu"
          dark={true}
          offsetTop={-50}
        />

        <Animated animationIn="fadeInDown" animateOnMount={false}>
          <Slider
            infinite={false}
            swipe={true}
            className={styles.slider}
            arrows={false}
            afterChange={this.onChangeIndex}
            ref={node => (this.slider = node)}
          >
            <div className={cn(styles.slide, styles.slideLight)}>
              <p>
                <strong>Electrolux</strong> is truly global and holds a strong position in every market. The focus is on
                its premium and progressive position, leveraging its existing qualities of Swedish values, Scandinavian
                design and professional edge.
                <br />
                <br />
                <strong>AEG</strong> is Europe-centric but growing in importance elsewhere, highlighted as a visionary
                premium brand, crafted with German engineering, durability and innovation.
                <br />
                <br />
                <strong>Frigidaire</strong> is primarily in the US market and speaks directly to the American heartland
                as a champion of the everyday life, making previously premium innovation affordable to more families
                than ever before.
                <br />
                <br />
                Come and visit our target audience in the brand zones…
              </p>
            </div>
            <div className={cn(styles.slide, styles.slideLight)}>
              <img src={brandsGraph} alt="graph" className={styles.graph} />
            </div>
          </Slider>
        </Animated>

        <Animated animationIn="fadeInUp" animateOnMount>
          <div className={cn(styles.sliderDots, styles.dark)}>
            {[1, 2].map((slide, i) => (
              <div className={cn(index === i && styles.activeDot)} key={i} />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
