import React, { Component } from 'react'
import { Animated } from 'react-animated-css'
import Header from 'components/Header'
import Slider from 'react-slick'
import cn from 'classnames'
import CircularProgressbar from 'react-circular-progressbar'
import _ from 'lodash'
import CountUp from 'react-countup'

import * as idleActions from 'actions/idle'

import styles from './styles.module.scss'

const slides = [
  {
    percent: 65,
    desc: (
      <p>
        <strong>Consumers typically shortlist less than 4 brands</strong>
        <br />
        <br />
        Approximately 65% of consumers start the shopping journey with four or less brands top of mind.
      </p>
    ),
  },
  {
    percent: 50,
    desc: (
      <p>
        <strong>…and generally buy from that shortlist</strong>
        <br />
        <br />
        Approximately 50% of consumers end up buying the first brand they had in mind.
      </p>
    ),
  },
  {
    percent: 60,
    desc: (
      <p>
        <strong>Consumers online tend to use filters to narrow down choice…</strong>
        <br />
        <br />
        Approximately 60% of consumers shopping appliances online use filters. The most popular filter is BRAND.
      </p>
    ),
  },
  {
    percent: 75,
    desc: (
      <p>
        <strong>A desirable brand gives profitable growth, within our industry & beyond</strong>
        <br />
        <br />
        Approximately 75% of value share performance within our industry can be explained by Top 3 Consideration &
        Spontaneous Awareness
      </p>
    ),
  },
]

export default class WhyBrandsMatter extends Component {
  state = {
    index: 0,
    showedIndexes: [],
  }

  componentDidMount = () => {
    idleActions.set()
    this.setInterval()
    setTimeout(() => {
      this.setState({ showedIndexes: [0] })
    }, 500)
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  setInterval = () => {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.onNextSlide()
    }, 15000)
  }

  onChangeIndex = (index, e = null) => {
    if (e) e.stopPropagation()

    this.setState({ index, showedIndexes: _.union(this.state.showedIndexes, [index]) }, () => {
      this.slider.slickGoTo(this.state.index)
      this.setInterval()
    })
  }

  onNextSlide = () => {
    const { index } = this.state

    if (index < slides.length - 1) {
      this.onChangeIndex(index + 1)
    } else {
      // this.onChangeIndex(0)
      this.props.history.push('/power-of-brands/menu/second-menu')
    }
  }

  onClick = () => {
    this.onNextSlide()
    idleActions.set()
  }

  render = () => {
    const { index, showedIndexes } = this.state

    return (
      <div className={styles.container} onClick={this.onClick}>
        <Header
          title="Why brands matter?"
          path={[
            { title: 'The power of OUR brands', to: '/power-of-brands/menu/second-menu' },
            { title: 'Why brands matter?', to: '/power-of-brands/menu/second-menu/why-brands-matter' },
          ]}
          backTo="/power-of-brands/menu/second-menu"
          dark={true}
        />

        <Slider
          infinite={false}
          swipe={true}
          className={styles.slider}
          arrows={false}
          afterChange={this.onChangeIndex}
          ref={node => (this.slider = node)}
        >
          {slides.map((slide, i) => (
            <div className={styles.slide} key={i}>
              <CircularProgressbar
                percentage={showedIndexes.includes(i) ? slide.percent : 0}
                initialAnimation={true}
                className={styles.progressBar}
                strokeWidth={5}
                styles={{
                  path: { stroke: `rgb(1, 30, 65)`, strokeLinecap: 'unset' },
                  trail: { stroke: `rgb(223, 231, 234)` },
                }}
              />
              <div className={styles.percent}>
                ~{showedIndexes.includes(i) ? <CountUp end={slide.percent} start={0} duration={2} /> : 0}%
              </div>

              {slide.desc}
            </div>
          ))}
        </Slider>

        <Animated animationIn="fadeInUp" animateOnMount animationInDelay={500}>
          <div className={styles.sliderDots}>
            {slides.map((slide, i) => (
              <div
                className={cn(index === i && styles.activeDot)}
                onClick={e => {
                  idleActions.set()
                  this.onChangeIndex(i, e)
                }}
                key={i}
              />
            ))}
          </div>
        </Animated>
      </div>
    )
  }
}
